html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  background-color: #fff;
  overflow-x: hidden;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  // line-height: 1.42857143;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.App {
  background-image: url("https://ongnhuahdpevietnam.com/wp-content/uploads/2022/08/anh-nen-trang-dep.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  a {
    text-decoration: none;
    outline: none;
  }
  .container_main {
    width: 100%;
  }
  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .divider {
    min-height: 2px;
    background-image: url(https://dungmori.com/assets/img/new_home/12-2021/divider.svg);
    background-size: cover;
    background-position: center;
  }
}
span.MuiTypography-body1.MuiTypography-displayBlock {
  font-size: 16px;
}
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.line {
  width: 100%;
  height: 6rem;
  overflow: hidden;
  text-align: center;
  font-size: 3rem;
  padding: 0;
  margin-bottom: 16px;
  color: #ff1361;
}
.lineUp {
  animation: 5s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 3.35rem !important;
}
.wrapper {
  .children {
    -webkit-animation: menuLearn 0.2s ease-in;
    animation: menuLearn 0.2s ease-in;

    .title {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .content {
      .content_1 {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        span.icon {
          transition: all 0.5s ease-in-out;
          svg {
            top: 50%;
            right: 50%;
            &:last-child {
              display: none;
            }
            &:first-child {
              display: inline-block;
            }
          }
          &.up {
            svg {
              &:first-child {
                display: none;
              }
              &:last-child {
                display: inline-block;
              }
            }
          }
        }
      }
      .content_2 {
        .item {
          transition: all 0.1s ease-out;

          &.active {
            background-color: #bfc7f0;
            h3,
            span {
              font-size: 1.4rem;
              font-weight: 600;
              color: #333;
            }
          }
          &.learn {
            .learn_icon {
              color: #5db85c;
            }
          }
        }
      }
    }
  }
}

.menuCourses {
  transition: all 0.4s ease-in-out;
  animation: anim-lineUp 0.6s ease-in;
  //custom phần menu course
  @media (max-width: 700px) {
    .MuiTabs-scrollButtonsDesktop {
      display: inline-block !important;
    }
  }
  header {
    .MuiTabs-root {
      .MuiTab-root {
        min-width: 60px !important;
        .MuiTab-wrapper {
          font-weight: bold;
        }
      }
      .MuiTabScrollButton-root {
        .MuiSvgIcon-fontSizeSmall {
          font-size: 5rem !important;
          color: red;
        }
      }
    }
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.menu_sub {
  align-items: center;
  background-color: #f0f0f0;
  bottom: 0;
  box-shadow: 0 -2px 3px rgb(0 0 0 / 10%);
  display: flex;
  height: 50px;

  left: 0;
  position: fixed;
  right: 0;

  .btn_control {
    border-radius: 6px;
    color: #404040;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 8px 12px;
    transition: 0.3s ease;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .next {
    border: 1px solid red;
    color: red;
    margin-left: 12px;
  }
  .infor {
    align-items: center;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    .title {
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      font-size: 1.4rem;
      overflow: hidden;
    }
    .btn {
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      font-size: 1.6rem;
      height: 38px;
      justify-content: center;
      margin: 0 8px;
      width: 38px;
    }
  }
}
.course-page {
  ::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #212020;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
}

.course-page__video {
  transition: all 0.4s ease-in-out;
}

//css user được chọn
.userAdmin__active {
  background-image: linear-gradient(135deg, #008aff, #86d472);
  font-weight: 600;
}
//css nút yes thông báo popconfirm
.my-ok-button-class {
  background-color: red;
}
//css lại nut button ant
.form_input {
  svg {
    vertical-align: baseline !important;
  }
}

.ant-descriptions-item-content {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  border: 3px solid;
  border-color: #de3500 #0000 #fff #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.loader:before,
.loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.loader:after {
  border-color: #de3500 #0000 #0000 #0000;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.list_way {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' className='bg-%5B%23FFFFFF%5D'%3E%3Crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='2' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3E%3C/svg%3E");
}
.waypages {
  background-image: url("https://ongnhuahdpevietnam.com/wp-content/uploads/2022/08/anh-nen-trang-dep.jpg");
}
.trial_study {
  h1 {
    strong {
      background: -o-linear-gradient(
          190deg,
          #3d67ff 0%,
          #9773ff 50%,
          #efceff 80%
        ),
        #fff;
      background: linear-gradient(260deg, #3d67ff 0%, #9773ff 50%, #efceff 80%),
        #fff;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      font-family: "Roboto", Helvetica, Arial, sans-serif;
    }
  }
  p {
    strong {
      font-weight: 600;
      -webkit-text-fill-color: transparent;
      background: -webkit-linear-gradient(45deg, #5ebbff -33%, #a174ff 69%);
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
}
.way_button span {
  transition: all 0.3s linear;
  left: 0;
}
.way_button:hover span {
  left: 7px;
}
.menu_way {
  background-image: url("https://img.lovepik.com/background/20211021/medium/lovepik-the-backdrop-of-a-web-page-background-image_500073033.jpg");
}

//custom scrollbar
.my-course,
.chatbot {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9b9999;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  animation: myCourse ease-in-out 0.2s;
  @keyframes myCourse {
    0% {
      opacity: 0;
      height: 500px;
    }
    100% {
      opacity: 1;
    }
  }
}
// xét lại phần xem video khi ở trên điện thoại
.doc-content {
  padding: 0 !important;
  max-width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}

.pdfAndHomeWork {
  transition: all 0.5s ease-out;
  animation: menuLearn linear 0.5s;
}
@keyframes menuLearn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#page-container {
  height: fit-content;
  background: none !important;
  background-image: none !important;
  background-color: none !important;
}

@media (max-width: 600px) {
  .t.m0.x5.h4.y6d.ff2.fs1.fc1.sc1.ls2.ws6 {
    left: 0px !important;
  }
  .t.m0.x3.h3.y8f.ff1.fs1.fc1.sc1.ls3.ws4 {
    left: 0px !important;
  }
}

.mock_test {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 15px 15px;

  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 1px solid #e4e4e4;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  color: #232323 !important;
  -webkit-font-smoothing: antialiased;
  &:hover {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0 11px 9px -10px rgba(0, 0, 0, 0.52);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .icon_level {
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    background: #337ab7;
    border-radius: 3px;
    position: absolute;

    font-weight: 600;
    color: #fff;
    top: 15px;
    right: 15px;

    font-size: 14px;
    border-radius: 2px;
    line-height: 24px;
  }
}

// phần custom styles cho bài tập
.t {
  /* position: absolute; */
  white-space: pre;
  font-size: 2rem;
  font-weight: 500;
  transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -webkit-transform-origin: 0 100%;
  unicode-bidi: bidi-override;
  -moz-font-feature-settings: "liga" 0;
  font-weight: 600;
  text-align: -webkit-match-parent;
  padding: 0 12rem;
}

.input_homeWork {
  position: relative;
  transform: scale(5);
  cursor: pointer;
  margin-right: 20px;

  left: -2rem;
  top: -0.7rem;
}

#handboy {
  animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 98% 98%;
  transform-box: fill-box;
}

#girllight {
  animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 0% 97%;
  transform-box: fill-box;
}

#hairgirl {
  animation: swinghair ease-in-out 1.3s infinite alternate;
  transform-origin: 60% 0%;
  transform-box: fill-box;
}

#zero {
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes swinghair {
  0% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-6deg);
  }
}
.basic {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  span {
    line-height: 2rem;
  }
  p {
    padding-top: 1rem;
  }
}
.WordSection1 {
  padding-left: 10px;
  padding-right: 10px;
}
#page-container {
  .c {
    overflow: unset !important;
  }
}

@media (max-width: 500px) {
  .basic * {
    font-size: 1.6rem !important;
  }
  .basic p {
    padding-top: 1rem;
  }
  .basic span {
    line-height: 2rem;
  }
  #page-container * {
    max-width: 370px;
    font-size: 45px !important;
  }
  #page-container .t {
    left: 0 !important;
  }
  .app-container *:not(label) {
    font-size: 16px !important;
  }
  .WordSection1 * {
    font-size: 16px !important;
  }
}
// phần css của bài thi
.c {
  max-height: 100px !important;
}

@media (max-width: 700px) {
  .modal__gpt {
    width: 100% !important;
    top: 70px !important;
  }
}

//phần text editor (tinymce)
.main__tinymce {
  ul,
  ol {
    list-style: inside;
  }
  a {
    text-decoration: underline;
    color: #008aff;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    display: initial;
    vertical-align: middle;
  }
  img,
  video,
  iframe {
    max-width: 100%;
  }
}
.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
