/*--header main start here--*/
.page-container {
  min-width: 1260px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px auto;
}
.left-content {
  float: right;
  width: 86.5%;
}
.page-container.sidebar-collapsed {
  transition: all 100ms linear;
  transition-delay: 300ms;
}
.page-container.sidebar-collapsed .left-content {
  float: right;
  width: 96%;
}
.page-container.sidebar-collapsed-back {
  transition: all 100ms linear;
}
.page-container.sidebar-collapsed-back .left-content {
  transition: all 100ms linear;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  float: right;
  width: 86%;
}
.page-container.sidebar-collapsed .sidebar-menu {
  width: 65px;
  transition: all 100ms ease-in-out;
  transition-delay: 300ms;
}

.page-container.sidebar-collapsed-back .sidebar-menu {
  width: 230px;
  transition: all 100ms ease-in-out;
}

.page-container.sidebar-collapsed .sidebar-icon {
  transition: all 300ms ease-in-out;
  color: #fff;
  background: #68ae00;
}
.page-container.sidebar-collapsed .logo {
  box-sizing: border-box;
  transition: all 100ms ease-in-out;
  transition-delay: 300ms;
  left: 18px;
}
.page-container.sidebar-collapsed-back .logo {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  transition: all 100ms ease-in-out;
}

.page-container.sidebar-collapsed #logo {
  opacity: 0;
  transition: all 200ms ease-in-out;
  display: none;
}
.page-container.sidebar-collapsed .down {
  display: none;
}
.page-container.sidebar-collapsed-back #logo {
  opacity: 1;
  transition: all 200ms ease-in-out;
  transition-delay: 300ms;
}

.page-container.sidebar-collapsed #menu span {
  opacity: 0;
  transition: all 50ms linear;
}

.page-container.sidebar-collapsed-back #menu span {
  opacity: 1;
  transition: all 200ms linear;
  transition-delay: 300ms;
}
.sidebar-menu {
  position: absolute;
  float: left;
  width: 220px;
  top: 0px;
  left: 0px;
  bottom: 0;
  background-color: #202121;
  color: #aaabae;
  z-index: 999;
}
#menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
#menu li {
  position: relative;
  margin: 0;
  font-size: 12px;
  padding: 0;
}
#menu li ul {
  opacity: 0;
  height: 0px;
}
#menu li a {
  position: relative;
  display: block;
  padding: 13px 20px;
  color: #ffffff;
  white-space: nowrap;
  z-index: 2;
  font-size: 1.12em;
  text-align: center;
  font-family: "Carrois Gothic", sans-serif;
}
#menu li a:hover {
  color: #fdbb30;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
}
#menu li.active > a {
  background-color: #2b303a;
  color: #ffffff;
}
#menu ul li {
  background-color: #202121;
}
#menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#menu li ul {
  position: absolute;
  visibility: hidden;
  left: 100%;
  top: 20px;
  background-color: #2b303a;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.1s linear;
  border-top: 1px solid rgba(69, 74, 84, 0.7);
}
#menu li:hover > ul {
  visibility: visible;
  opacity: 1;
}
#menu li li ul {
  right: 100%;
  visibility: hidden;
  top: -1px;
  opacity: 0;
  transition: opacity 0.1s linear;
}
#menu li li:hover ul {
  visibility: visible;
  opacity: 1;
}
#menu .fa {
  margin-bottom: 10px;
  font-size: 1.5em;
  display: block;
}
.menu {
  padding: 4.2em 0em 0em 0em;
}
/*----*/
.page-container.sidebar-collapsed .left-content .fixed {
  width: 97%;
}
/*----*/
.logo {
  width: 22%;
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  left: 170px;
}
.sidebar-icon {
  position: relative;
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 18px;
  padding: 6px 8px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  color: #fff;
  background-clip: padding-box;
  background: #68ae00;
}
.sidebar-icon:hover {
  color: #fff;
}
.fa-html5 {
  color: #fff;
  margin-left: 50px;
}
/*--nav strip start here--*/
.header-main {
  background: #fff;
  padding: 1em 2em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.header-right {
  float: right;
  width: 50%;
}
.header-left {
  float: left;
  width: 45%;
}
.logo-name {
  float: left;
  width: 30%;
  margin: 0% 3% 0% 2%;
}
.logo-name h1 {
  font-size: 2.5em;
  color: #fc8213;
  margin: 0em;
  font-weight: 700;
  text-decoration: none;
}
.logo-name h1 a {
  color: #910048;
}
.logo-name a {
  display: inline-block;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background: none !important;
}
span.logo-clr {
  color: #fdbb30;
}
.page-container.sidebar-collapsed-back #menu span.fa.fa-angle-right {
  position: absolute;
  top: 0px;
  right: 20px;
}
/*start search*/
.search-box {
  float: left;
  width: 40%;
  margin-top: 0.5em;
  position: relative;
  z-index: 1;
  display: inline-block;
  border: 2px solid rgb(197, 197, 197);
}
.search-box input[type="text"] {
  outline: none;
  background: #fff;
  width: 86%;
  margin: 0;
  z-index: 10;
  font-size: 0.9em;
  color: #7a7b78;
  padding: 0.5em 0.5em;
  border: none;
  -webkit-appearance: none;
  display: inline-block;
}
.search-box input[type="submit"] {
  background: red;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: text-top;
  border: none;
  outline: none;
}
::-webkit-input-placeholder {
  color: #7a7b78 !important;
}
/*--//search-ends --*/
/*--- Progress Bar ----*/
.meter {
  position: relative;
}
.meter > span {
  display: block;
  height: 100%;

  position: relative;
  overflow: hidden;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.red > span {
  background-color: #65cea7;
}

.nostripes > span > span,
.nostripes > span:after {
  -webkit-animation: none;
  -moz-animation: none;
  background-image: none;
}
/*--- User Panel---*/
.profile_details_left {
  float: left;
  width: 50%;
}
.dropdown-menu {
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.175);
  border-radius: 0;
}
li.dropdown.head-dpdn {
  display: inline-block;
  padding: 1em 0;
  float: left;
}
li.dropdown.head-dpdn a.dropdown-toggle {
  margin: 1em 2em;
}
ul.dropdown-menu li {
  margin-left: 0;
  width: 100%;
  padding: 0;
  background: #fff;
}
.user-panel-top ul {
  padding-left: 0;
}
.user-panel-top li {
  float: left;
  margin-left: 15px;
  position: relative;
}
.user-panel-top li span.digit {
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  background: #e64c65;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -o-border-radius: 2em;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: -10px;
}
.user-panel-top li:first-child {
  margin-left: 0;
}
.sidebar .nav-second-level li a.active,
.sidebar ul li a.active {
  color: #f2b33f;
}
li.active a i,
.act a i {
  color: #f2b33f;
}
.custom-nav > li.act > a,
.custom-nav > li.act > a:hover,
.custom-nav > li.act > a:focus {
  background-color: #353f4f;
  color: #8bc34a;
}
.user-panel-top li a {
  display: block;
  padding: 5px;
  text-decoration: none;
}
.header-right i.fa.fa-envelope {
  color: #6f6f6f;
}
i.fa.fa-bell {
  color: #6f6f6f;
}
i.fa.fa-tasks {
  color: #6f6f6f;
}
.user-panel-top li a:hover {
  border-color: rgba(101, 124, 153, 0.93);
}
.user-panel-top li a i {
  width: 24px;
  height: 24px;
  display: block;
  text-align: center;
  line-height: 25px;
}
.user-panel-top li a i span {
  font-size: 15px;
  color: #fff;
}
.user-panel-top li a.user {
  background: #667686;
}
.user-panel-top li span.green {
  background: #a88add;
}
.user-panel-top li span.red {
  background: #b8c9f1;
}
.user-panel-top li span.yellow {
  background: #bdc3c7;
}
/***** Messages *************/
.notification_header {
  background-color: #fafafa;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
}
.notification_header h3 {
  color: #6a6a6a;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}
.notification_bottom {
  background-color: rgba(93, 90, 88, 0.07);
  padding: 4px 0;
  text-align: center;
  margin-top: 5px;
}
.notification_bottom a {
  color: #6f6f6f;
  font-size: 1em;
}
.notification_bottom a:hover {
  color: #6164c1;
}
.notification_bottom h3 a {
  color: #717171;
  font-size: 12px;
  border-radius: 0;
  border: none;
  padding: 0;
  text-align: center;
}
.notification_bottom h3 a:hover {
  color: #4a4a4a;
  text-decoration: underline;
  background: none;
}
.user_img {
  float: left;
  width: 19%;
}
.user_img img {
  max-width: 100%;
  display: block;
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -o-border-radius: 2em;
}
.notification_desc {
  float: left;
  width: 70%;
  margin-left: 5%;
}
.notification_desc p {
  color: #757575;
  font-size: 13px;
  padding: 2px 0;
}
.wrapper-dropdown-2 .dropdown li a:hover .notification_desc p {
  color: #424242;
}
.notification_desc p span {
  color: #979797 !important;
  font-size: 11px;
}
/*---bages---*/
.header-right span.badge {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: #fc8213;
  line-height: 10px;
  width: 15px;
  height: 15px;
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -o-border-radius: 2em;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 20%;
  padding: 2px 0 0;
  left: 54%;
}
.header-right span.blue {
  background-color: #337ab7;
}
.header-right span.red {
  background-color: #ef553a;
}
.header-right span.blue1 {
  background-color: #68ae00;
}
i.icon_1 {
  float: left;
  color: #00aced;
  line-height: 2em;
  margin-right: 1em;
}
i.icon_2 {
  float: left;
  color: #ef553a;
  line-height: 2em;
  margin-right: 1em;
  font-size: 20px;
}
i.icon_3 {
  float: left;
  color: #9358ac;
  line-height: 2em;
  margin-right: 1em;
  font-size: 20px;
}
.avatar_left {
  float: left;
}
i.icon_4 {
  width: 45px;
  height: 45px;
  background: #f44336;
  float: left;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  line-height: 44px;
  font-style: normal;
  margin-right: 1em;
}
i.icon_5 {
  background-color: #3949ab;
}
i.icon_6 {
  background-color: #03a9f4;
}
.blue-text {
  color: #2196f3 !important;
  float: right;
}
/*---//bages---*/
/*--Progress bars--*/
.progress {
  height: 10px;
  margin: 7px 0;
  overflow: hidden;
  background: #e1e1e1;
  z-index: 1;
  cursor: pointer;
}
.task-info .percentage {
  float: right;
  height: inherit;
  line-height: inherit;
}
.task-desc {
  font-size: 12px;
}
.wrapper-dropdown-3 .dropdown li a:hover span.task-desc {
  color: #65cea7;
}
.progress .bar {
  z-index: 2;
  height: 15px;
  font-size: 12px;
  color: white;
  text-align: center;
  float: left;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-striped .yellow {
  background: #f0ad4e;
}
.progress-striped .green {
  background: #5cb85c;
}
.progress-striped .light-blue {
  background: #4f52ba;
}
.progress-striped .red {
  background: #d9534f;
}
.progress-striped .blue {
  background: #428bca;
}
.progress-striped .orange {
  background: #e94e02;
}
.progress-striped .bar {
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
/*--Progress bars--*/
/********* profile details **********/
ul.dropdown-menu.drp-mnu i.fa {
  margin-right: 0.5em;
}
ul.dropdown-menu {
  padding: 0;
  min-width: 230px;
  top: 101%;
}
.dropdown-menu > li > a {
  padding: 3px 15px;
  font-size: 1em;
}
.profile_details {
  float: right;
}
.profile_details_drop .fa.fa-angle-up {
  display: none;
}
.profile_details_drop.open .fa.fa-angle-up {
  display: block;
}
.profile_details_drop.open .fa.fa-angle-down {
  display: none;
}
.profile_details_drop a.dropdown-toggle {
  display: block;
  padding: 0em 3em 0 1em;
}
.profile_img span.prfil-img {
  float: left;
}
.user-name {
  float: left;
  margin-top: 7px;
  margin-left: 11px;
  height: 35px;
}
.profile_details ul li {
  list-style-type: none;
  position: relative;
}
.profile_details li a i.fa.lnr {
  position: absolute;
  top: 34%;
  right: 8%;
  color: #68ae00;
  font-size: 1.6em;
}
.profile_details ul li ul.dropdown-menu.drp-mnu {
  padding: 1em;
  min-width: 190px;
  top: 122%;
  left: 0%;
}
ul.dropdown-menu.drp-mnu li {
  list-style-type: none;
  padding: 3px 0;
}
.user-name p {
  font-size: 1em;
  color: #fc8213;
  line-height: 1em;
  font-weight: 700;
}
.user-name span {
  font-size: 0.75em;
  font-style: italic;
  color: #424f63;
  font-weight: normal;
  margin-top: 0.3em;
}
.profile_details ul {
  padding: 0px;
}
/*--header strip end here-*/
/*inner-block--*/
.inner-block {
  padding: 8em 2em 4em 2em;
  background: #fafafa;
}
.market-update-block {
  padding: 2em 2em;
  background: #999;
}
.market-update-block h3 {
  color: #fff;
  font-size: 2.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.market-update-block h4 {
  font-size: 1.2em;
  color: #fff;
  margin: 0.3em 0em;
  font-family: "Carrois Gothic", sans-serif;
}
.market-update-block p {
  color: #fff;
  font-size: 0.8em;
  line-height: 1.8em;
}
.market-update-block.clr-block-1 {
  background: #68ae00;
  margin-right: 0.8em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-2 {
  background: #fc8213;
  margin-right: 0.8em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-3 {
  background: #337ab7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-1:hover {
  background: #3c3c3c;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-2:hover {
  background: #3c3c3c;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-3:hover {
  background: #3c3c3c;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-right i.fa.fa-file-text-o {
  font-size: 3em;
  color: #68ae00;
  width: 80px;
  height: 80px;
  background: #fff;
  text-align: center;
  border-radius: 49px;
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  -o-border-radius: 49px;
  line-height: 1.7em;
}
.market-update-right i.fa.fa-eye {
  font-size: 3em;
  color: #fc8213;
  width: 80px;
  height: 80px;
  background: #fff;
  text-align: center;
  border-radius: 49px;
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  -o-border-radius: 49px;
  line-height: 1.7em;
}
.market-update-right i.fa.fa-envelope-o {
  font-size: 3em;
  color: #337ab7;
  width: 80px;
  height: 80px;
  background: #fff;
  text-align: center;
  border-radius: 49px;
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  -o-border-radius: 49px;
  line-height: 1.7em;
}
.market-update-left {
  padding: 0px;
}
/*--main page charts strat here--*/
/*--chart layer-1 left--*/

.glocy-chart {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 2em 1em;
  background: #fff;
}
.prograc-blocks {
  padding: 2.2em 2em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
canvas#bar {
  width: 584px !important;
  height: 300px !important;
}
h3.tlt {
  font-size: 1.3em;
  margin-bottom: 0.8em;
  font-weight: 700;
  color: #5f5d5d;
  text-transform: uppercase;
  font-family: "Carrois Gothic", sans-serif;
}
/*--chart-layer1-right start--*/
.home-progres-main {
  padding-bottom: 9px;
  margin: 0px 0 20px;
}
.home-progres-main h3 {
  font-size: 1.3em;
  font-weight: 700;
  color: #5f5d5d;
  text-transform: uppercase;
  font-family: "Carrois Gothic", sans-serif;
}
.home-progress {
  height: 21px;
  margin: 20px 0;
  overflow: hidden;
  background: #e1e1e1;
  z-index: 1;
  cursor: pointer;
  border-radius: 4px;
}
.progress-bar1 {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
/*--moveing effect prograce bars--*/
.bar_group__bar.thin::before {
  display: block;
  content: "";
  position: absolute;
  z-index: -1;
}

.bar_group__bar.thin::before {
  width: 100%;
  height: 5px;
  border-radius: 0px;
  background: #e4e4e4;
}

.bar_group__bar.thin {
  width: 0%;
  height: 5px;
  border-radius: 0px;
  background: #90b82d;
  margin-bottom: 10px;
  -webkit-transition: width 1s;
  transition: width 1s;
}
.b_label,
.bar_label_min,
.bar_label_max,
.b_tooltip span {
  color: #999;
  font-size: 14px;
  margin: 0.5em 0;
}
.bar_group.group_ident-1 {
  padding-right: 0em;
  z-index: 0;
  position: relative;
}
.bar_label_max {
  position: absolute;
  right: 0%;
}
.bar_label_min {
  position: absolute;
}
.b_tooltip {
  -webkit-transition: all 1s;
  transition: all 1s;
  position: relative;
  float: left;
  left: 100%;
  padding: 4px 10px 7px 10px;
  background-color: rgb(74, 74, 73);
  -webkit-transform: translateX(-50%) translateY(-30px);
  -ms-transform: translateX(-50%) translateY(-30px);
  transform: translateX(-50%) translateY(-30px);
  -o-transform: translateX(-50%) translateY(-30px);
  border-radius: 0px;
  line-height: 11px;
}
.b_tooltip span {
  color: white;
}
.b_tooltip--tri {
  width: 0;
  height: 0;
  position: absolute;
  content: "";
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: rgb(74, 74, 73) transparent transparent transparent;
}
/*--chartlayer 2 start here--*/
.chart-layer-2 {
  margin: 2em 0em;
}
.chart-layer2-left h3 {
  font-size: 1.3em;
  font-weight: 700;
  color: #5f5d5d;
  text-align: left;
  text-transform: uppercase;
  font-family: "Carrois Gothic", sans-serif;
}
canvas#radar {
  width: 565px !important;
  height: 350px !important;
}
/*--maloum grid start here--*/
.malorum-top {
  background: red;
  background-size: cover;
  min-height: 190px;
}
.malorm-bottom {
  padding: 1.5em 2em;
  position: relative;
  background: #fff;
}
span.malorum-pro {
  background: red;
  width: 95px;
  height: 95px;
  display: inline-block;
  position: absolute;
  top: -60px;
  left: 265px;
  border: 2px solid #ccc;
  border-radius: 63px;
  -webkit-border-radius: 63px;
  -moz-border-radius: 63px;
  -o-border-radius: 63px;
}
.malorm-bottom h2 {
  font-size: 1.5em;
  color: #68ae00;
  text-align: center;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.malorm-bottom h4 {
  font-size: 1em;
  margin: 0.5em 0em;
  color: #fc8213;
  font-family: "Carrois Gothic", sans-serif;
}
.malorm-bottom p {
  font-size: 1em;
  color: #000;
  line-height: 1.8em;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.user-marorm {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.malorm-bottom ul {
  list-style: none;
  padding: 0px;
  text-align: center;
  margin-top: 1em;
}
.malorm-bottom ul li {
  display: inline-block;
  margin-right: 10px;
}
.malorm-bottom i.fa.fa-facebook {
  font-size: 1em;
  color: #fff;
  background: #3c579e;
  width: 30px;
  height: 30px;
  line-height: 2em;
  transition: 0.5s all;
}
.malorm-bottom i.fa.fa-facebook:hover {
  border-radius: 35px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.malorm-bottom i.fa.fa-twitter {
  font-size: 1em;
  color: #fff;
  background: #0f98ce;
  width: 30px;
  height: 30px;
  line-height: 2em;
  transition: 0.5s all;
}
.malorm-bottom i.fa.fa-twitter:hover {
  border-radius: 35px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.malorm-bottom i.fa.fa-google-plus {
  font-size: 1em;
  color: #fff;
  background: #ca2429;
  width: 30px;
  height: 30px;
  line-height: 2em;
  transition: 0.5s all;
}
.malorm-bottom i.fa.fa-google-plus:hover {
  border-radius: 35px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}

.malorum-icons li a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.malorum-icons a:hover .tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.malorum-icons a:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.malorum-icons .tooltip {
  opacity: 0;
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.malorum-icons .tooltip span {
  position: relative;
  left: -50%;
  padding: 6px 8px 5px 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.7em;
  line-height: 1;
  background: #565656;
  color: #fff;
  letter-spacing: 0.5px;
}
.malorum-icons .tooltip span:after {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border: 8px solid transparent;
  border-top-color: #565656;
}
.malorum-icons i {
  position: relative;
  top: 1px;
  font-size: 1.5rem;
}
/*--chit chart layer start here--*/
.panel {
  padding: 1em 1em;
  margin-bottom: 0px;
  background: none;
  box-shadow: none;
}
.chit-chat-layer1 {
  margin: 2em 0em;
}
.chit-chat-heading {
  font-size: 1.2em;
  font-weight: 700;
  color: #5f5d5d;
  text-transform: uppercase;
  font-family: "Carrois Gothic", sans-serif;
}
.work-progres {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 1.34em 1em;
  background: #fff;
}
/*--geochart start here--*/
section#charts1 {
  padding: 0px;
}
.geo-chart {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 1em 1em;
  background: #fff;
}
.revenue {
  padding: 1em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
div#geoChart {
  width: 100% !important;
  height: 305px !important;
  border: 4px solid #fff;
}
h3#geoChartTitle {
  font-size: 1.3em;
  font-weight: 700;
  color: #5f5d5d;
  text-transform: uppercase;
  font-family: "Carrois Gothic", sans-serif;
}
/*--climate start here--*/
.climate-grid1 {
  background: red;
  min-height: 375px;
  background-size: cover;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.climate-gd1top-left h4 {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.climate-gd1top-left h3 {
  font-size: 2em;
  color: #fc8213;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.climate-gd1top-left p {
  font-size: 1em;
  color: #fff;
  line-height: 2em;
}
.climate-gd1top-right {
  font-size: 1em;
  color: #fff;
  line-height: 2em;
}
.climate-gd1-top {
  padding: 1em 1em;
  margin-bottom: 1.6em;
}
.climate-gd1-bottom {
  padding: 1em 0em;
  background: rgba(252, 130, 19, 0.86);
}
.cloudy1 h4 {
  font-size: 1em;
  color: #fff;
  margin-bottom: 0.5em;
}
.cloudy1 {
  text-align: center;
}
i.fa.fa-cloud {
  color: #fff;
  font-size: 2.5em;
  margin: 0.2em 0em 0.2em 0em;
}
.cloudy1 h3 {
  font-size: 1.2em;
  color: #fff;
}
span.timein-pms {
  font-size: 0.4em;
  vertical-align: top;
  color: #fff;
}
span.clime-icon {
  display: block;
  margin-bottom: 0.3em;
}
.climate-grid2 {
  background: red;
  min-height: 310px;
  background-size: cover;
  position: relative;
}
.shoppy {
  padding: 1.4em 1em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.climate-grid2 ul {
  position: absolute;
  bottom: -10px;
  right: 0px;
  list-style: none;
}
.climate-grid2 ul li {
  display: inline-block;
  margin-right: 0.5em;
}
.climate-grid2 ul li i.fa.fa-credit-card {
  width: 35px;
  height: 30px;
  display: inline-block;
  background: #337ab7;
  font-size: 1em;
  color: #ffffff;
  line-height: 2em;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
}
.climate-grid2 ul li i.fa.fa-credit-card:hover {
  background: #ec8526;
}
.climate-grid2 ul li i.fa.fa-usd {
  width: 35px;
  height: 30px;
  display: inline-block;
  background: #337ab7;
  font-size: 1em;
  color: #fff;
  line-height: 2em;
  text-align: center;
  border-radius: 4px;
}
.climate-grid2 ul li i.fa.fa-usd:hover {
  background: #ec8526;
}
span.shoppy-rate {
  background: #fc8213;
  margin: 1em 1em;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 49px;
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  -o-border-radius: 49px;
  display: inline-block;
}
span.shoppy-rate h4 {
  font-size: 1.2em;
  line-height: 3.5em;
  color: #fff;
  font-family: "Carrois Gothic", sans-serif;
}
.shoppy h3 {
  font-size: 1.2em;
  color: #68ae00;
  font-family: "Carrois Gothic", sans-serif;
}
.popular-brand {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.popular-bran-right {
  background: #fc8213;
  padding: 3em 1em;
}
.popular-bran-left {
  background: #fff;
  padding: 2em 1em;
}
.popular-bran-left h3 {
  font-size: 1.2em;
  color: #68ae00;
  margin-bottom: 0.2em;
  font-family: "Carrois Gothic", sans-serif;
}
.popular-bran-left h4 {
  font-size: 0.9em;
  color: #fc8213;
}
.popular-bran-right h3 {
  font-size: 1.3em;
  color: #fff;
  text-align: center;
}
.popular-bran-right h3 {
  font-size: 1.55em;
  color: #fff;
  width: 77px;
  height: 77px;
  margin: 0 auto;
  line-height: 2.8em;
  border-radius: 62px;
  -webkit-border-radius: 62px;
  -moz-border-radius: 62px;
  -o-border-radius: 62px;
  border: 3px solid #fff;
  font-family: "Carrois Gothic", sans-serif;
}
.popular-follo-left {
  background: #fdbb30;
}
.popular-follow {
  margin-top: 2.35em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.popular-follo-right {
  padding: 3em 1em;
  text-align: center;
}
.popular-follo-left {
  background: #68ae00;
  padding: 2.5em 1em;
}
.popular-follo-left p {
  font-size: 1em;
  color: #fff;
  line-height: 1.8em;
}
.popular-follo-right h4 {
  font-size: 1.5em;
  color: #fc8213;
  margin-bottom: 0.3em;
  font-family: "Carrois Gothic", sans-serif;
}
.popular-follo-right h5 {
  font-size: 1em;
  color: #68ae00;
}
.popular-bran-left p {
  font-size: 1em;
  color: #000;
  margin-top: 1.3em;
  line-height: 1.5em;
}
.climate-gd1top-right p {
  font-size: 1em;
  color: #fff;
}
/*--climate end here--*/
#style-2::-webkit-scrollbar-thumb {
  background-color: #000;
}
/*--copyrights start here--*/
.copyrights {
  padding: 1.5em 1em;
  text-align: center;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.copyrights p {
  font-size: 1em;
  color: #000;
}
.copyrights p a {
  color: #000;
}
.copyrights p a:hover {
  color: #68ae00;
}
/*--grids start here--*/
.cols-grids h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
/*-- grids --*/
.mb40 {
  margin-bottom: 40px !important;
}
.demo-grid {
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 10px 0;
}
code {
  padding: 0;
  background: none;
  color: #b7b7b7;
}
.mb40:nth-child(9) {
  margin-bottom: 0 !important;
}
.cols-grids {
  padding: 1em 1em;
  margin-bottom: 0px;
  background: none;
  box-shadow: none;
}
/*--portlet start here--*/
.portlet-grid {
  padding: 0px;
  width: 32%;
  float: left;
  margin: 0% 1% 3% 0%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
  padding: 25px;
  font-size: 1em;
}
.portlet-grid-page h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
/*--portlet end here--*/
/*--button start here--*/
.btn-effcts.panel-widget {
  padding: 1em;
  margin-top: 2em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.btn-main-heading h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.button-heading h4 {
  font-size: 1.8em;
  color: #337ab7;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
/*--button end here--*/
/*--icons --*/
.boost-icons-head h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.boost-icons-list ul {
  list-style: none;
  padding: 0px;
}
.boost-icons-list ul li {
  float: left;
  width: 19%;
  font-size: 1em;
  margin: 0% 1% 1% 0%;
  padding: 3em 0.5em;
  text-align: center;
  background: #ffffff;
  color: #000;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
i.glyphicon {
  display: block;
  text-align: center;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  color: #337ab7;
}
/*--//icons--*/
/*--typography--*/
.typography {
  font-family: "Carrois Gothic", sans-serif;
}
p.grid1 {
  margin-bottom: 1em;
}
.typography .progress {
  height: 20px;
  margin: 20px 0;
}
.page-header {
  padding-bottom: 9px;
  margin: 19px 0 12px;
  border: none;
}
.grid_3.grid_4 {
  background: #fff;
  padding: 1em 2em;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.typo-buttons {
  background: #fff;
  padding: 1em 2em;
  box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.15);
  margin: 2em 0em;
}
.typo-alerts {
  background: #fff;
  padding: 1em 2em;
  box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.typo-progresses {
  background: #fff;
  padding: 1em 2em;
  margin: 2em 0em;
  box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.typo-wells {
  background: #fff;
  padding: 1em 2em;
  margin-bottom: 2em;
  box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.typo-badges {
  background: #fff;
  padding: 1em 2em;
  box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.page-header h3 {
  font-size: 2em;
}
.typo-wells h3 {
  font-size: 2em;
  margin-bottom: 1em;
}
.typo-badges h3 {
  font-size: 2em;
  margin-bottom: 1em;
}
.col-lg-6.mb-60 .nav-tabs > li > a {
  background: #eceaea;
  color: #565555;
}
.tab-content {
  padding: 1em;
}
.tab-content p {
  font-size: 1em;
  color: #000;
  line-height: 1.8em;
}
.col-lg-6.mb-60 h4 {
  font-size: 2em;
  margin-bottom: 0.5em;
}
/*--login page--*/
.login-main {
  width: 40%;
  margin: 0 auto;
  background: #ffffff;
  background-size: cover;
  min-height: 400px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.login-page {
  padding: 6.5em 0em;
}
.login-head {
  background: red;
  background-size: cover;
  min-height: 150px;
}
.login-head h1 {
  font-size: 3em;
  color: #fff;
  padding: 1em 0em 0em 0em;
  text-align: center;
  font-family: "Carrois Gothic", sans-serif;
}
.login-block {
  padding: 4em 2em;
}
.login-block input[type="text"],
.login-block input[type="password"] {
  font-size: 0.9em;
  padding: 10px 20px;
  width: 100%;
  color: #8c8c8c;
  outline: none;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  -ms-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  background: #f5f5f5;
  margin: 0em 0em 1.5em 0em;
}
.login-block input[type="submit"] {
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  background: #337ab7;
  width: 100%;
  margin: 0 auto;
  border-radius: 3px;
  padding: 0.5em 1em;
  font-size: 1em;
  display: block;
  font-family: "Carrois Gothic", sans-serif;
}
.login-block input[type="submit"]:hover {
  background: #68ae00;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}
.forgot-top-grids {
  margin: 1em 0em;
  padding: 0em 1.5em;
}
.login-block label.checkbox {
  margin-left: 1.3em;
  margin-top: 0;
  font-size: 1em;
  color: #555;
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
}
.forgot {
  float: right;
  margin-top: 0.3em;
}
.forgot a {
  font-size: 0.75em;
  color: #fc8213;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}
.forgot a:hover {
  color: #68ae00;
}
.login-block h2 {
  color: #68ae00;
  font-size: 0.875em;
  margin: 1.2em 0;
  text-align: center;
  font-family: "Carrois Gothic", sans-serif;
}
/*--*/
.login-icons ul {
  text-align: center;
}
.login-icons ul li {
  display: inline-block;
  margin: 0 0.5em;
}
.login-icons ul li a {
  display: block;
}
.login-icons i.fa.fa-facebook,
.login-icons i.fa.fa-twitter,
.login-icons i.fa.fa-google-plus {
  font-size: 1em;
  color: #ffffff;
  width: 33px;
  height: 33px;
  line-height: 34px;
  vertical-align: middle;
  background: #3b5998;
  transition: 0.5s all;
}
.login-icons i.fa.fa-facebook:hover,
.login-icons i.fa.fa-twitter:hover,
.login-icons i.fa.fa-google-plus:hover {
  border-radius: 35px;
}
.login-icons i.fa.fa-twitter {
  background: #55acee;
}
.login-icons i.fa.fa-google-plus {
  background: #dc4e41;
}
.login-icons i.fa.fa-dribbble {
  background: #ea4c89;
}
.forgot-grid {
  float: left;
}
.forgot-grid ul {
  margin: 0;
  padding: 0px;
}
.forgot-grid ul li {
  list-style: none;
  display: inline-block;
}
.forgot-grid ul li input[type="checkbox"] {
  display: none;
}
.forgot-grid ul li input[type="checkbox"] + label {
  position: relative;
  padding-left: 31px;
  color: #fc8213;
  display: inline-block;
  cursor: pointer;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
}
.forgot-grid ul li input[type="checkbox"] + label span:first-child {
  width: 17px;
  height: 17px;
  display: inline-block;
  border: 2px solid #fc8213;
  position: absolute;
  left: 0;
  bottom: 4px;
}
.forgot-grid
  ul
  li
  input[type="checkbox"]:checked
  + label
  span:first-child:before {
  content: "";
  background: red;
  position: absolute;
  left: 1px;
  top: 2px;
  font-size: 10px;
  width: 10px;
  height: 10px;
}
.login-block h3 {
  font-size: 1em;
  color: #000;
  text-align: center;
  margin-top: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.login-block h3 a {
  color: #337ab7;
}
.login-block h3 a:hover {
  color: #fc8213;
}
.login-block h5 {
  font-size: 1em;
  text-align: right;
  margin-top: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.login-block h5 a {
  color: #fc8213;
  text-decoration: underline;
}
.login-block h5 a:hover {
  color: #68ae00;
  text-decoration: underline;
}
/*--login page end here--*/
/*--signup page start here--*/
.signup-page-main {
  padding: 9em 0em;
}
.signup-main {
  width: 40%;
  margin: 0 auto;
  background: #ffffff;
  background-size: cover;
  min-height: 400px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.signup-head {
  background: red;
  background-size: cover;
  min-height: 150px;
}
.signup-head h1 {
  font-size: 3em;
  color: #fff;
  padding: 1em 0em 0em 0em;
  text-align: center;
  font-family: "Carrois Gothic", sans-serif;
}
.signup-block {
  padding: 4em 2em;
}
.signup-block input[type="text"],
.signup-block input[type="password"] {
  font-size: 0.9em;
  padding: 10px 20px;
  width: 100%;
  color: #686967;
  outline: none;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  -ms-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  background: #f5f5f5;
  margin: 0em 0em 1.5em 0em;
}
.signup-block input[type="submit"] {
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  background: #337ab7;
  width: 100%;
  margin: 0 auto;
  border-radius: 3px;
  padding: 0.5em 1em;
  font-size: 1em;
  display: block;
  font-family: "Carrois Gothic", sans-serif;
}
.signup-block input[type="submit"]:hover {
  background: #68ae00;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}
.sign-down h4 {
  font-size: 1em;
  color: #000;
  text-align: center;
  font-family: "Carrois Gothic", sans-serif;
}
.sign-down h4 a {
  font-size: 1em;
  color: #000;
  text-align: center;
  margin-top: 1em;
  display: inline-block;
}
.sign-down h4 a:hover {
  color: #68ae00;
}
.sign-down h5 {
  font-size: 1em;
  text-align: center;
  margin-top: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.sign-down h5 a {
  color: #337ab7;
  text-decoration: underline;
}
.sign-down h5 a:hover {
  color: #68ae00;
}
/*--signup page end here--*/
/*--maps start here--*/
.popin {
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 20px #999;
  border-radius: 2px;
}
.map-main-page h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
#map1a,
#panorama {
  height: 300px;
  background: #6699cc;
}
.map-system {
  margin-bottom: 2em;
}
/*--maps end here--*/
/*--balank page start here--*/
.blank {
  min-height: 600px;
}
.blank h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.blankpage-main {
  padding: 2em 2em;
  background: #fff;
  margin-top: 4em;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.blankpage-main p {
  font-size: 1.1em;
  color: #000;
  line-height: 1.8em;
}
/*--blank page end here--*/
/*--charts start here--*/
.dygno h2 {
  font-size: 1.5em;
  color: #000;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.line-chart {
  padding: 2em 2em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.polararea {
  background: #fff;
  padding: 2em 2em;
  text-align: center;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.line-chart h3 {
  font-size: 1.5em;
  color: #000;
  font-family: "Carrois Gothic", sans-serif;
  margin-bottom: 0.5em;
}
.chart-other h3 {
  font-size: 1.5em;
  color: #000;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.polararea h3 {
  font-size: 1.5em;
  color: #000;
  text-align: left;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.dygno {
  padding: 2em 2em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.chart-other {
  padding: 2em 2em;
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
canvas#line {
  width: 560px !important;
  height: 300px !important;
}
canvas#polarArea {
  width: 400px !important;
  height: 270px !important;
}
.chart-blo-1 {
  padding-bottom: 2em;
}
canvas#pie {
  width: 470px !important;
  height: 270px !important;
}

/*--charts end here--*/
/*--error page start here--*/
.error-404 {
  text-align: center;
}
.error-404 h2 {
  font-size: 6em;
  color: #63c5ca;
}
.error-404 h2 {
  font-size: 3em;
  color: #fc8213;
  margin: 0.5em 0em;
  font-family: "Carrois Gothic", sans-serif;
}
.error-404 a {
  font-size: 1em;
  color: #fff;
  margin-top: 2em;
  background: #68ae00;
  padding: 0.5em 1em;
  display: inline-block;
}
.error-404 a:hover {
  background: #337ab7;
}
.error-404 {
  min-height: 620px;
  margin-top: 2em;
}
.error-right h4 {
  font-size: 1.3em;
  color: #000;
}
/*--inbox start here--*/
.float-left {
  float: left;
  width: 40%;
}
.float-right {
  float: right;
  width: 50%;
}
.btn.btn_1.btn-default.mrg5R {
  float: left;
  width: 15%;
  margin: 0% 5% 0% 0%;
}
.mailbox-content {
  background: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
table.table.tab-border {
  border: 1px solid #d4d4d4;
}
.mail-toolbar.clearfix {
  padding: 1em 1em;
}
.dropdown-inbox {
  float: right;
  width: 80%;
}
.hidden-xs i.fa.fa-star.icon-state-warning {
  color: #fc9513;
}
.hidden-xs i.fa.fa-star {
  color: #999;
}
.hidden-xs i.fa.fa-star:hover {
  color: #fc9513;
}
.btn-group.m-r-sm.mail-hidden-options {
  margin: 0em 1em;
}
.mail-profile {
  background: #68ae00;
  padding: 1em 1em;
}
.mail-profile {
  background: #fc8213;
  padding: 1em 1em;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.mail-pic {
  float: left;
  width: 30%;
}
.mailer-name {
  float: right;
  width: 70%;
  margin-top: 1.4em;
}
.mailer-name h5 {
  font-size: 1.1em;
  margin-bottom: 0.3em;
  font-family: "Carrois Gothic", sans-serif;
}
.mailer-name h5 a {
  color: #fff;
}
.mailer-name h6 {
  font-size: 0.8em;
  margin-bottom: 0.3em;
  font-family: "Carrois Gothic", sans-serif;
}
.mailer-name h6 a {
  color: #fff;
}
.mailer-name h6 a:hover {
  color: #000;
}
.compose-block {
  padding: 1.5em 1em;
  text-align: center;
  background: #dedede;
}
.compose-block a {
  font-size: 1em;
  color: #fff;
  background: #68ae00;
  padding: 0.5em 1.5em;
  border-radius: 3px;
  display: inline-block;
}
.compose-block a:hover {
  background: #337ab7;
}
.compose-bottom ul {
  list-style: none;
  padding: 0px;
}
.compose-bottom ul li a {
  padding: 1em 1em;
  background: #eaeaea;
  display: block;
  font-size: 1em;
  color: #797878;
  font-family: "Carrois Gothic", sans-serif;
}
.compose-bottom ul li a.hilate {
  background: #c5c5c5;
}
.compose-bottom ul li a:hover {
  background: #c5c5c5;
}
.compose-bottom i.fa.fa-inbox {
  font-size: 1.1em;
  margin-right: 12px;
  color: #797878;
}
.compose-bottom i.fa.fa-envelope-o {
  font-size: 1.1em;
  margin-right: 12px;
  color: #797878;
}
.compose-bottom i.fa.fa-star-o {
  font-size: 1.1em;
  margin-right: 12px;
  color: #797878;
}
.compose-bottom i.fa.fa-pencil-square-o {
  font-size: 1.1em;
  margin-right: 12px;
  color: #797878;
}
.compose-bottom i.fa.fa-trash-o {
  font-size: 1.1em;
  margin-right: 12px;
  color: #797878;
}
ul.nav.tabs li a span {
  background: #de3939;
  border-radius: 15px;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
  width: 17px;
  height: 17px;
  display: inline-block;
  float: right;
}
.inbox h2 {
  font-size: 2em;
  color: #68ae00;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
/*--inbox end here--*/
/*--inbox details--*/
.compose-right .inbox-details-heading {
  padding: 0.8em 2em;
  background: #e4e4e4;
}
.compose-right .inbox-details-body {
  padding: 2em;
}
.compose-right .alert.alert-info {
  padding: 10px 20px;
  font-size: 0.9em;
  color: #fff;
  background-color: #337ab7;
  border-color: rgba(51, 122, 183, 0.82);
  border-radius: inherit;
}
.compose-right .form-group {
  margin: 0.5em 0;
}
.compose-right .btn.btn-file {
  position: relative;
  overflow: hidden;
  border-radius: inherit;
  cursor: pointer;
}
.inbox-details-default {
  background: #fff;
  font-family: "Carrois Gothic", sans-serif;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
}
.compose-right .btn.btn-file > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  outline: none;
  background: white;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  padding: 0.4em;
}
.compose-right p.help-block {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.9em;
  color: #6f6f6f;
}
.inbox-details-body input[type="text"] {
  border: 1px solid #ccc;
  padding: 5px 8px;
  color: #616161;
  background: #fff;
  box-shadow: none !important;
  width: 100%;
  font-size: 0.85em;
  font-weight: 300;
  height: 40px;
  border-radius: 0;
  -webkit-appearance: none;
  resize: none;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.inbox-details-body textarea {
  border: 1px solid #ccc;
  padding: 5px 8px;
  color: #616161;
  background: #fff;
  box-shadow: none !important;
  width: 100%;
  font-size: 0.85em;
  font-weight: 300;
  height: 14em;
  border-radius: 0;
  -webkit-appearance: none;
  resize: none;
  margin-bottom: 1em;
  font-family: "Carrois Gothic", sans-serif;
}
.compose-right input[type="submit"] {
  font-size: 0.9em;
  background-color: #68ae00;
  border: none;
  color: #fff;
  padding: 0.8em 1.5em;
  margin-top: 1em;
  outline: none;
}
.compose-right input[type="submit"]:hover {
  background-color: #fc8213;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.compose-right {
  padding: 0px;
}
/*--inbox details--*/
/*--product start here--*/
.product-items {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 3em;
}
.pro-head h2 {
  font-size: 2em;
  color: #68ae00;
  margin: 0em 0em 1em 0.4em;
  font-family: "Carrois Gothic", sans-serif;
}
.produ-cost {
  padding: 0.8em 1.5em;
  background: #337ab7;
}
.produ-cost h4 {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 0.5em;
  font-family: "Carrois Gothic", sans-serif;
}
.produ-cost h5 {
  font-size: 1em;
  color: #fff;
  font-family: "Carrois Gothic", sans-serif;
}
/*--light-box--*/
.project-eff:hover span.rollover1 {
  background: red;
  width: 300px;
  height: 215px;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: -9999px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.project-eff {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
}
/*--product end here--*/
/*--prices start here--*/
.prices-head h2 {
  font-size: 2em;
  color: #68ae00;
  margin: 0em 0em 1em 0.4em;
  font-family: "Carrois Gothic", sans-serif;
}
.price-list ul {
  padding: 0px;
  list-style: none;
}
.price-gd-top {
  background: #78a239;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
}
.price-gd-top h4 {
  font-size: 1.8em;
  color: #fff;
  padding: 0.4em 1em;
  background: #467501;
  border-radius: 5px 5px 0px 0px;
  font-family: "Carrois Gothic", sans-serif;
}
.price-gd-top h3 {
  padding: 0.4em 0em;
  font-size: 3.3em;
  color: #fff;
  font-family: "Carrois Gothic", sans-serif;
}
span.usa-dollar {
  font-size: 0.4em;
  vertical-align: top;
}
span.per-month {
  font-size: 0.4em;
}
.price-gd-top h5 {
  font-size: 1em;
  color: #fff;
  padding: 0.2em 0em 0.8em 0em;
  font-family: "Carrois Gothic", sans-serif;
}
.price-gd-bottom {
  background: #fff;
  text-align: center;
  padding: 1em 0em;
}
.price-list ul li {
  padding: 0.5em 0em;
  font-size: 1em;
  color: #545454;
}
.price-selet {
  padding: 1em 0em;
  text-align: center;
  background: #78a239;
  border-radius: 0px 0px 5px 5px;
}
.price-selet a {
  font-size: 1.1em;
  color: #fff;
  display: block;
}
.price-selet a {
  font-size: 1.1em;
  color: #fff;
  display: block;
}
.price-grid {
  margin-bottom: 3em;
}
.pric-clr1 h4 {
  background: #09375f;
}

.pric-clr1 {
  background: #1a63a0;
}
.pric-clr3 h4 {
  background: #cc660e;
}
.pric-clr3 {
  background: #e07d28;
}
.price-block {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.price-block:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  z-index: 1;
}
a.popup-with-zoom-anim {
  outline: none;
}
/*--pricing-grids--*/

#small-dialog h4 {
  margin: 10px 0;
  font-size: 1.5em;
  color: #337ab7;
  font-family: "Carrois Gothic", sans-serif;
}

#small-dialog,
#small-dialog1,
#small-dialog2,
#small-dialog3,
#small-dialog4,
#small-dialog5 {
  background: white;
  padding: 10px 0 10px 0;
  text-align: left;
  max-width: 629px;
  margin: 40px auto;
  position: relative;
  text-align: center;
  border: 8px solid #68ae00;
  height: 475px;
  border-radius: 5px;
}
/*---start-pricing-tabels-----*/
.payment-online-form-left {
}
form li {
  list-style: none;
}
form ul {
  padding: 0;
}
h4.payment-head {
  font-size: 1.9em;
  color: #222222;
  padding: 0.5em 0;
  text-align: left;
  float: left;
}
.payment-online-form-left span {
  vertical-align: sub;
  margin-right: 7px;
}
.payment-online-form-left form {
  padding: 0 3%;
}
.payment-online-form-left input[type="text"] {
  padding: 0.7em 1em;
  color: #6b6b6a;
  width: 47%;
  margin: 0.5em 0;
  border: 1px solid;
  outline: none;
  transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  float: left;
  font-size: 0.9em;
  border-color: #e67c1b;
  -webkit-appearance: none;
}
input#datepicker {
  padding: 1em 1em 1em 1em;
}
.payment-online-form-left input[type="text"]:hover {
  border-color: #fa6e6f;
}
.text-box-dark {
}
.text-box-light {
  background: red;
  cursor: pointer;
  position: relative;
}
.payment-online-form-left ul li:first-child input[type="text"] {
  margin-right: 5%;
}
.payment-online-form-left input[type="text"]:active,
.payment-online-form-left input[type="text"]:hover {
  border-color: #fc8213;
  color: #6b6b6a;
}
.shoppong-pay-1 {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: red;
}
.payment {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: red;
}
.payment-date-section {
  background: red;
}
.payment-sendbtns {
  float: right;
  margin: 1.2em 0 1em;
}
.payment-sendbtns input[type="reset"] {
  background: #68ae00;
  padding: 10px 25px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.95em;
  display: inline-block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;
  border-radius: 3px;
}
.payment-sendbtns input[type="reset"]:hover {
  color: #fff;
  background: #fc8213;
}
a.order {
  background: #fc8213;
  padding: 10px 25px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.95em;
  display: inline-block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-appearance: none;
  outline: none;
  text-decoration: none;
  border-radius: 3px;
}
a.order:hover {
  color: #fff;
  background: #68ae00;
}
.payment-sendbtns li {
  display: inline-block;
}
.payment-type li {
  display: inline-block;
}
.payment-online-form-right {
  background: #2b2937;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border-bottom: 4px solid #1a1822;
}
/*-----*/
.get-started {
  background: red;
  min-height: 400px;
  padding: 8% 0 0 0;
  color: #fff;
  background-size: cover;
  text-align: center;
}
.get-started h4 {
  font-size: 1.5em;
  margin-bottom: 1em;
  font-weight: 400;
  padding: 0;
}
.get-started h3 {
  font-size: 2.8em;
  font-weight: 400;
  margin-bottom: 1em;
  padding: 0;
}
.get-started a {
  padding: 12px 18px;
  border: 1px solid #ff0426;
  font-size: 1.2em;
  color: #fff;
  outline: none;
  font-weight: 400;
  text-decoration: none;
  background: #f94877;
}
.get-started a:hover {
  background: transparent;
  border: 1px solid #f94877;
}
/*-----*/
.visa {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: red;
}
.paypal {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: red;
}
.payment-online-form-right {
  float: right;
  width: 30%;
  background: #2b2937;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border-bottom: 4px solid #1a1822;
  padding-bottom: 2em;
}
.payment-online-form-right a:hover {
  color: #1abc9c;
}
.payment-online-form-right ul li {
  display: block;
  padding: 0.5em;
}
.payment-online-form-right ul li a {
  color: #9095aa;
}
.payment-online-form-right h4 {
  color: #fff;
  font-size: 1em;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(144, 149, 170, 0.22);
  padding: 0.8em;
}
.payment-online-form-right ul {
  padding: 0px 1em;
}
.payment-online-form-right h5 {
  color: #fff;
  padding: 0.5em 0.8em 0.4em;
  font-size: 1em;
}
.payment-type {
  margin-top: 3%;
  text-align: left;
}
input[type="checkbox"].css-checkbox3,
input[type="checkbox"].css-checkbox4,
input[type="checkbox"].css-checkbox5,
input[type="checkbox"].css-checkbox6 {
  display: none;
}
input[type="checkbox"].css-checkbox3 + label.css-label3 {
  height: 22px;
  width: 21px;
  display: inline-block;
  line-height: 18px;
  background-repeat: no-repeat;
  background-position: 0 -38px;
  vertical-align: middle;
  cursor: pointer;
  background-image: red;
}
input[type="checkbox"].css-checkbox3:checked + label.css-label3 {
  background-position: 0px 0px;
}
input[type="checkbox"].css-checkbox4 + label.css-label4 {
  height: 22px;
  width: 21px;
  display: inline-block;
  line-height: 18px;
  background-repeat: no-repeat;
  background-position: 0 0px;
  vertical-align: middle;
  cursor: pointer;
  background-image: red;
}
input[type="checkbox"].css-checkbox4:checked + label.css-label4 {
  background-position: 0px -38px;
}
input[type="checkbox"].css-checkbox5 + label.css-label5 {
  height: 22px;
  width: 21px;
  display: inline-block;
  line-height: 18px;
  background-repeat: no-repeat;
  background-position: 0 -38px;
  vertical-align: middle;
  cursor: pointer;
  background-image: red;
}
input[type="checkbox"].css-checkbox5:checked + label.css-label5 {
  background-position: 0px 0px;
}
input[type="checkbox"].css-checkbox6 + label.css-label6 {
  height: 22px;
  width: 21px;
  display: inline-block;
  line-height: 18px;
  background-repeat: no-repeat;
  background-position: 0 0px;
  vertical-align: middle;
  cursor: pointer;
  background-image: red;
}
input[type="checkbox"].css-checkbox6:checked + label.css-label6 {
  background-position: 0px -38px;
}

/* start state */
.my-mfp-zoom-in #small-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
/* animate in */
.my-mfp-zoom-in.mfp-ready #small-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/* animate out */
.my-mfp-zoom-in.mfp-removing #small-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}
/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
/**
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #151515;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-hide {
  display: none !important;
}
.mfp-content iframe {
  width: 100%;
  min-height: 500px;
}
.mfp-preloader {
  color: #cccccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #cccccc;
}
.mfp-preloader a:hover {
  color: white;
}
.mfp-s-ready .mfp-preloader {
  display: none;
}
.mfp-s-error .mfp-content {
  display: none;
}
button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  border: 0;
  background: #fc8213;
  -webkit-appearance: none;
  display: block;
  padding: 0;
  z-index: 1046;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mfp-close {
  width: 35px;
  height: 35px;
  line-height: 17px;
  position: absolute;
  right: 0%;
  top: 0%;
  text-decoration: none;
  text-align: center;
  color: white;
  font-style: normal;
  font-size: 28px;
  outline: none;
  padding: 0 !important;
}
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}
.mfp-close-btn-in .mfp-close {
  color: #ffffff;
  padding: 0 0 7px 0px;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}
.image-top img {
  width: 100%;
}
.image-top p {
  text-align: justify;
  padding: 2% 0;
  font-size: 15px;
}
.image-top h3 {
  font-size: 1.5em;
  font-weight: 500;
  margin: 16px 0px 0px 0px;
  color: #3f4244;
}
p.bottom {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  padding: 15px 0;
  color: #999;
  font-size: 14px;
}
