.introduce {
  &_title {
    height: 56px;
    transform: rotate(-3deg);
    font-family: Montserrat, Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    padding: 15px 40px 15px 30px;
    position: absolute;
    top: -3px;
    left: 8px;
    z-index: 0;
    border: 1px solid transparent;
    border-right: none;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      border-right: 15px solid transparent;
      border-bottom: 3rem solid #ffd4d4;
      border-top: 3rem solid #ffd4d4;
    }
  }
  .tab_inner {
    text-align: center;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding: 6px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    // color: #293142;
    text-transform: uppercase;
  }
  .tab_link {
    font-family: Quicksand, Arial, sans-serif;
    font-weight: 600;

    // margin: auto 20px;
    border-radius: 10px;
    text-decoration: none;
    position: relative;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #41a336;
      color: #fff;
    }
  }
  .tab_btn {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding: 16px 35px;
    border-radius: 40px;
    border: 1px solid #293142;
    background-color: #fff;
    text-decoration: none;
    color: #293142;
    display: inline-flex;
    margin: 30px auto;
    box-shadow: 0 5px 0 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 5px 0 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.2);
    transition: 0.15s ease-in-out;
    &:hover {
      background-color: #787878;
    }
  }
  .preview {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    padding: 20px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(246, 240, 236, 0.5);
    position: relative;
    transition: all 0.3s ease-in-out;
    &_inside {
      flex: 1;
      box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
      border-radius: 15px;
      overflow: hidden;
    }
  }
}
