.movie__container {
  width: 100%;
  // height: 100%;
  display: flex;
  img {
    width: 100%;
    height: 260px;
    object-fit: cover;
  }
}

.movie__container .flow span {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
.movie__container .flow h2 {
  text-transform: capitalize;
  color: rgb(226, 226, 226);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: left;
}

.movie__container .right {
  width: 100%;
  right: 0;
  background-color: #111;
  // padding-bottom: 300px;
}
.right .heading {
  display: flex;
  color: rgb(226, 226, 226);
}
.right .heading .head-content {
  width: 50%;
  // padding: 20px;
  background-color: black;
  // justify-content: space-around;
}

.head-content .rating {
  display: flex;
  // margin: 30px 0;
}
.head-content .btn {
  display: inline-block;
  width: 80px;
  height: 30px;
  border: 1px solid white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
  border-radius: 50px;
}
.head-content .btn .material-icons {
  font-size: 20px;
}

.head-content .btn .star {
  color: greenyellow;
}

.head-content p {
  font-size: 16px;
  letter-spacing: 1px;
  // margin-bottom: 30px;
}

.head-content .btn-main {
  padding: 10px 0;
  border-radius: 5px;
  color: rgb(226, 226, 226);
  text-decoration: none;
  width: 120px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: red;
  border: none;
  text-transform: capitalize;
  &:hover {
    background-color: blue;
    color: #fff;
  }
}

.right .heading .img-container {
  width: 50%;

  background: no-repeat center center / cover;
  position: relative;
  &::after {
    content: attr(data-value);
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    font-size: 30px;
    color: #fff;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.right .heading .img-container img {
  width: 100%;
  height: 100%;
}

.trending {
  margin-top: 40px;
}
.title__film {
  padding-left: 2rem;
  color: #fff;
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: 500;
}
h1 span {
  font-size: 16px;
  color: red;
  font-weight: lighter;
  margin-left: 20px;
}

.trending .movie-container {
  cursor: pointer;
  margin-top: 30px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 18px;
}
.list {
  position: relative;
  &:hover {
    opacity: 0.9;
  }
  &::after {
    content: attr(data-value);
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #fff;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub__list {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}

.list::before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: all 0.4s;
  z-index: 1000;
}

.list:hover::before {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.list::before {
  content: "Xem ngay";
}

.tv {
  margin-bottom: 50px;
}

.video__item {
  display: flex;
  padding: 20px;
  justify-content: space-around;
  gap: 30px;
  border-bottom: 1px solid #1f1f1f;
  overflow: hidden;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  &.active {
    .name {
      font-size: 14px !important;
      color: red !important;
    }
    .genre {
      font-size: 14px;
      color: #696969;
      font-weight: 600;
    }
    .view {
      font-size: 14px;
      font-weight: 600;

      color: #696969;
    }
  }
  &:hover {
    background-color: #e3cfcf;
  }
  .language {
    background: #333;
    color: #fff;
    padding: 2px 4px;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    right: 5px;
    z-index: 2;
  }
  .img {
    width: 17%;
    height: 60px;
    float: left;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::before {
      content: attr(data-value);
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
      font-size: 12px;
      color: #fff;
      background-color: orange;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    float: left;
    margin-left: 10px;
    z-index: 1;
    position: relative;
    flex: 1;
    line-height: 18px;
    color: #f2f2f2;
    .name {
      font-size: 12px;
      color: #fff;
      font-weight: 700;
      // margin-bottom: 5px;
      // margin-right: 5px;
    }
    .genre {
      font-size: 12px;
      color: #696969;
    }
    .view {
      font-size: 12px;

      color: #696969;
    }
  }
}
.video__main {
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #212020;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
}
.movies__course {
  background: url("https://file.hstatic.net/1000302121/file/unnamed-min_cdb267ec98fc40c6afc5267a08b83874_grande.png");
}
.react__player {
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
